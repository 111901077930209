<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
    <!-- 底部 -->
    <div class="bottomDiv">
      <div><span class="btn" @click="onClickBttonBtn('agree')">User Agreement</span><span class="line">|</span><span class="btn" @click="onClickBttonBtn('policy')">Privacy Policy</span></div>
      <div>Mail：{{ mailUrl }}</div>
      <div>Copyright © 2020-2024 Kiwitok. All rights Reserved.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      mailUrl: "service@Kiwitok.com"
    };
  },
  methods: {
    onClickBttonBtn(type) {
      let url = type == "agree" ? "userAgreement" : "privacyPolicy";
      this.$router.push("/" + url);
    }
  }
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html,
body,
#app {
  height: 100%;
  margin: 0px;
}
</style>
<style lang="scss" scoped>
.bottomDiv {
  text-align: center;
  color: #aaaaac;
  font-size: 12px;
  // margin-top: 10px;
  line-height: 1.6;
  padding: 24px 0;
  .btn {
    color: #000;
  }
  .line {
    margin: 0px 10px;
  }
}
</style>
