<template>
  <div class="homePageDiv">
    <!-- 轮播图 -->
    <el-carousel ref="myCarousel" class="myCarousel" :height="carouselHeight" v-if="dataObj.IMAGE.list.length > 0" trigger="click" autoplay arrow="never">
      <el-carousel-item v-for="(item, index0) in dataObj.IMAGE.list" :key="'IMAGE_' + index0" @click="onClickItem(item)">
        <el-image style="width: 100%; height: 100%" :src="item.imageUrl" fit="contain">
          <div slot="placeholder" class="image-slot">Loading<span class="dot">...</span></div>
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </el-carousel-item>
    </el-carousel>
    <!-- 排行榜 -->
    <div v-if="dataObj.ROW.list.length > 0" class="rowDiv">
      <p class="title">{{ dataObj.ROW.name }}<img class="arrowImg" src="@/assets/arrow.png" alt="" /></p>
      <div class="scroll-container" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd" ref="scrollContainer">
        <div class="scroll-content" :style="{ transform: `translateX(${offsetX}px)` }" id="rowList">
          <!-- 这里放置你要滚动的内容 -->
          <div v-for="(rowItem, index1) in dataObj.ROW.list" :key="'row_' + index1" :class="dataObj.ROW.list.length > 3 ? 'rowItem' : 'rowItem rowItem1'" @click="onClickItem(rowItem)">
            <!-- <img class="rowImage" src="@/assets/a_100535261_m_601_m30_260_360.jpg" /> -->
            <el-image class="rowImage" :src="rowItem.coverImg" fit="contain">
              <div slot="placeholder" class="image-slot">Loading<span class="dot">...</span></div>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <div class="name">{{ rowItem.name }}</div>
            <img class="numImg" v-if="index1 == 0" src="@/assets/first.png" alt="" />
            <img class="numImg" v-else-if="index1 == 1" src="@/assets/second.png" alt="" />
            <img class="numImg" v-else-if="index1 == 2" src="@/assets/third.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div v-if="dataObj.LIST.list.length > 0" class="listDiv">
      <p class="title">{{ dataObj.LIST.name }}</p>
      <div class="listContentDiv">
        <div v-for="(listItem, index2) in dataObj.LIST.list" :key="'list_' + index2" class="listItem" @click="onClickItem(listItem)">
          <el-image class="itemImage" :src="listItem.coverImg" fit="contain">
            <div slot="placeholder" class="image-slot">Loading<span class="dot">...</span></div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <!-- <img class="itemImage" src="@/assets/a_100535261_m_601_m30_260_360.jpg" /> -->
          <div class="name">{{ listItem.name }}</div>
          <div>
            <span class="count">{{ listItem.videoCount + " Episodes" }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import httpTools from "@/utils/httpAPI/httpTools";
// import { GPTAsync, Slot } from "@googleads/googleads";
export default {
  data() {
    return {
      dataObj: {
        IMAGE: {
          name: "",
          loading: true,
          list: []
        },
        LIST: {
          name: "",
          loading: true,
          list: []
        },
        ROW: {
          name: "",
          loading: true,
          list: []
        }
      },
      windowW: 0,
      windowH: 0,

      startX: 0,
      offsetX: 0,
      rowW: 0,
      rowScrollW: 0,

      showADing: false
    };
  },
  computed: {
    carouselHeight() {
      let _w = this.windowW - 28;
      let num = 750 / 246;
      let _h = _w / num;
      return _h + "px";
    }
  },
  created() {
    this.windowW = window.innerWidth;
    this.windowH = window.innerHeight;
    this.getHomePageData();
  },
  mounted() {
    console.log("-homepage-------mounted");
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    console.log("清理事件--------destroyed");
    // if (window.googletag) {
    //   window.googletag.pubads().clear();
    // }
  },
  methods: {
    onResize() {
      this.windowW = window.innerWidth;
    },
    getHomePageData() {
      httpTools.get_homePage_data().then((res) => {
        if (res.status == "SUCCESS") {
          let componentList = res.content.componentList;
          componentList.forEach((element) => {
            element.list = [];
            element.loading = true;
            this.dataObj[element.layout] = element;
            this.getPageComponentData(element);
          });
        } else {
          this.$message.error("Please contact the administrator!");
        }
      });
    },
    getPageComponentData(element) {
      httpTools.getPageComponent(element.componentId).then((res) => {
        if (res.status == "SUCCESS") {
          let val = "playletList";
          if (element.layout == "IMAGE") {
            val = "bannerList";
          }
          this.dataObj[element.layout].list = res.content[val];
          this.dataObj[element.layout].loading = false;
          if (element.layout == "ROW") {
            this.$nextTick(() => {
              let rowListDiv = document.getElementById("rowList");
              this.rowW = rowListDiv.offsetWidth;
              this.rowScrollW = rowListDiv.scrollWidth;
            });
          }
        } else {
          this.$message.error("Please contact the administrator!");
        }
      });
    },

    handleTouchStart(e) {
      this.startX = e.touches[0].clientX;
    },
    handleTouchMove(e) {
      const currentX = e.touches[0].clientX;
      const deltaX = currentX - this.startX;
      this.offsetX += deltaX;
      this.startX = currentX;
      if (this.offsetX > 0) {
        this.offsetX = 0;
      }
      if (this.offsetX < this.rowW - this.rowScrollW) {
        this.offsetX = this.rowW - this.rowScrollW;
      }
    },
    handleTouchEnd() {
      // 可以在这里添加滑动结束后的逻辑，例如回弹效果
      console.log("this.offsetX:", this.offsetX);
      console.log("this.startX:", this.startX);
      if (this.offsetX > 0) {
        this.offsetX = 0;
      }
      if (this.offsetX < this.rowW - this.rowScrollW) {
        this.offsetX = this.rowW - this.rowScrollW;
      }
    },
    onClickItem(item) {
      this.$router.push("/videoDetails/" + item.playletId + "/0");
    }
  }
};
</script>
<style lang="scss" scoped>
.homePageDiv {
  padding-top: 14px;
  .myCarousel {
    border-radius: 5px;
    margin: 0px 14px 14px;
    ::v-deep .el-carousel__button {
      width: 4px;
      height: 4px;
      border-radius: 3px;
    }
    // ::v-deep .el-carousel__indicators--horizontal {
    //   right: 25px;
    //   left: 0px;
    //   transform: none;
    // }
  }
  .rowDiv {
    margin: 0px 14px;
    .title {
      margin: 0px;
      font-size: 18px;
      margin-bottom: 14px;
      font-weight: bold;
      text-align: left;
      .arrowImg {
        width: 12px;
        height: 12px;
        vertical-align: middle;
      }
    }

    .scroll-container {
      overflow: hidden;
      white-space: nowrap;
    }
    .scroll-content {
      display: flex;
      .rowItem {
        width: 104px;
        margin-right: 10px;
        position: relative;
        .rowImage {
          width: 104px;
          border-radius: 5px;
        }
        .name {
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          width: 100%;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 溢出部分隐藏 */
          text-overflow: ellipsis; /* 显示省略号 */
        }
        .numImg {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 26px;
        }
      }
      .rowItem1 {
        margin-right: calc((100% - 312px) / 2);
      }
      .rowItem:last-child {
        margin-right: 0px;
      }
    }
  }
  .listDiv {
    margin: 0px 14px;
    .title {
      margin: 0px;
      margin-top: 14px;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
    }
    .listContentDiv {
      display: flex;
      flex-wrap: wrap;
      .listItem {
        width: calc((100% - 5px) / 2);
        margin-right: 5px;
        margin-top: 14px;
        text-align: left;
        .itemImage {
          width: 100%;
          border-radius: 5px;
        }
        .name {
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          width: 100%;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 溢出部分隐藏 */
          text-overflow: ellipsis; /* 显示省略号 */
          margin-bottom: 6px;
        }
        .count {
          font-size: 12px;
          color: #77797d;
          background-color: #edeef2;
          padding: 3px 4px;
          border-radius: 5px;
        }
      }
      .listItem:nth-child(2n) {
        margin-right: 0px;
      }
    }
  }
}
</style>
