import Vue from "vue";
import Router from "vue-router";
import homePage from "@/view/homePage";
import videoDetails from "@/view/videoDetails";
import userAgreement from "@/view/userAgreement";
import privacyPolicy from "@/view/privacyPolicy";

Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
let routes = [
  {
    path: "/",
    name: "homePage",
    component: homePage
  },
  {
    path: "/videoDetails/:playletId/:playletResId",
    name: "videoDetails",
    component: videoDetails,
    // props: true // 将路由参数作为组件的 props 传递
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: privacyPolicy
  },
  {
    path: "/userAgreement",
    name: "userAgreement",
    component: userAgreement
  }
];
const router = new Router({
  //  mode: 'history',
  mode: process.env.IS_ELECTRON ? "hash" : "history",
  base: process.env.BASE_URL,
  routes
});

// 使用全局前置守卫来重置滚动位置
router.beforeEach((to, from, next) => {
  if (to.path !== from.path) {
    window.scrollTo(0, 0); // 滚动到页面的顶部
  }
  console.log("to.matched：", to);
  // if (to.matched.some((record) => record.path.indexOf(":playletId/:playletResId") > -1)) {
  //   console.log("to:", to);
  //   next({ path: `/videoDetails/${to.params.playletId}/${to.params.playletResId}` }); // 假设你有一个userId变量
  // } else {
  //   next();
  // }
  next(); // 必须调用next()来resolve这个钩子
});

export default router;
