import request from "./request.js";
const homepageUrl = "/api/page/getPage";
const getPageComponentUrl = "/api/page/getPageComponent";
const getVideoDetailByWebUrl = "/api/playlet/getVideoDetailByWeb";
const getVideoPlayAuthUrl = "/api/playlet/getVideoPlayAuth";
export default {
  /**
   * 获取首页布局数据
   * @returns
   */
  get_homePage_data() {
    let url = `${homepageUrl}?uri=home`;
    return request({
      url: url,
      method: "get"
    });
  },
  /**
   * 查询组件内容
   * @returns
   */
  getPageComponent(componentId) {
    let url = `${getPageComponentUrl}?componentId=${componentId}`;
    return request({
      url: url,
      method: "get"
    });
  },
  /**
   * 查询视频详情
   * @returns
   */
  getVideoDetailByWeb(playletId) {
    let url = `${getVideoDetailByWebUrl}?playletId=${playletId}`;
    return request({
      url: url,
      method: "get"
    });
  },
  /**
   * 获取播放凭证
   * @returns
   */
  getVideoPlayAuth(playletResId) {
    let url = `${getVideoPlayAuthUrl}?playletResId=${playletResId}`;
    return request({
      url: url,
      method: "get"
    });
  }
};
