<template>
  <div class="videoDetailsDiv" v-loading="!videoDetailsData">
    <div class="topDiv">
      <img class="returnImg" @click="onClickReturn" src="@/assets/return.png" alt="" />
      <span class="title_p">{{ videoDetailsData.name }}</span>
      <div></div>
    </div>
    <div class="videoDiv">
      <div class="videoPlayer">
        <div id="J_prismPlayer"></div>
      </div>
      <!-- 需要播放激励广告蒙版 -->
      <div class="maskDiv" v-if="!videoData.playStatus" ref="mackDiv" id="mackDiv">
        <div>
          <div class="lockImgDiv">
            <img class="lockImg" src="@/assets/lock.png" alt="" />
          </div>
          <p class="lockDsc">Need to see ads.</p>
          <div class="btnDiv">
            <el-button class="btn" :loading="unblockDisabled" :disabled="unblockDisabled" @click="onClickPay">Unblock </el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频下方 内容 -->
    <div class="contentDiv">
      <p class="desc_p" v-if="videoDetailsData.description">{{ videoDetailsData.description }}</p>
      <p class="title_p mg_top_24">
        Selection<span class="desc_p2">{{ videoDetailsData.videoCount }} episodes in total</span>
      </p>
      <p class="desc_p3" v-if="videoData.fileName">{{ videoData.fileName }}</p>
      <!-- 集数 -->
      <div class="playList">
        <div class="playItem" :class="item.playletResId == videoData.playletResId ? 'playItem_active' : ''" :id="'playItem_' + item.playletResId" v-for="(item, index) in videoDetailsData.playletResList" :key="index" @click="onClickPlay(item)">
          <div>{{ index + 1 }}</div>
          <img class="payImg" v-if="showLock(item)" src="@/assets/paylock.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import httpTools from "@/utils/httpAPI/httpTools";

export default {
  data() {
    return {
      videoDetailsData: "",
      videoSrc: "",
      videoData: "",
      playletId: "",
      player: "",
      currentVideoIndex: 0,
      unblockDisabled: false,
      unblockTimeOut: "",
      showADing: false,
      thePlayletResId: 0,

      adsManager: "",
      adsLoader: "",
      adDisplayContainer: "",
      playButton: "",
      videoContent: "",
      adsInitialized: "",
      autoplayAllowed: "",
      autoplayRequiresMuted: "",
      showCoverImg: true,
      adRecordObj: {},
      isReresh: true
      // showSliceAD:true
    };
  },
  computed: {
    showPlayBtn() {
      if (this.player && this.player.getStatus() != "playing") {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.playletId = this.$route.params.playletId;
    console.log("this.$route.params:", this.$route.params);
    // this.showSliceAD = this.$route.params.showAD=='true'?true:false
    if (localStorage.getItem("kiwitok_seeAD_record")) {
      let val = localStorage.getItem("kiwitok_seeAD_record");
      this.adRecordObj = JSON.parse(val);
    }
    this.getVideoDetailByWeb(this.playletId, this.$route.params.playletResId);
  },
  mounted() {
    console.log("video-details----mounted:");
  },
  beforeDestroy() {
    console.log("videoDetails--------beforeDestroy");
    if (this.player) {
      this.player.pause();
      this.player.dispose();
      this.player = null;
    }
  },
  methods: {
    showLock(item) {
      return !item.playStatus && !(this.adRecordObj[this.playletId] && this.adRecordObj[this.playletId].indexOf(item.playletResId) != -1);
    },
    /**
     * 获取视频集数列表
     * @param {*} playletId
     * @param {*} playletResId
     */
    getVideoDetailByWeb(playletId, playletResId) {
      httpTools.getVideoDetailByWeb(playletId).then((res) => {
        if (res.status == "SUCCESS") {
          if (this.adRecordObj[playletId] && this.adRecordObj[playletId].length > 0) {
            res.content.playletResList.forEach((el) => {
              if (el.playStatus == false && this.adRecordObj[playletId].indexOf(el.playletResId) != -1) {
                el.playStatus = true;
              }
            });
          }
          this.videoDetailsData = res.content;
          if (playletResId == "0") {
            this.thePlayletResId = this.videoDetailsData.playletResList[0].playletResId;
            // playletResId = this.videoDetailsData.playletResList[0].playletResId;
            this.currentVideoIndex = 0;
          } else {
            this.thePlayletResId = playletResId;
            this.currentVideoIndex = this.videoDetailsData.playletResList.findIndex((item) => item.playletResId == playletResId);
          }
          this.videoData = this.videoDetailsData.playletResList[this.currentVideoIndex];
          // this.videoData.isSeeCom = false;
          // if (this.videoData.playStatus) {
          this.getVideoPlayAuth(this.thePlayletResId, false);
          // }
          // 控制当前集数显示在屏幕上
          this.$nextTick(() => {
            let el = document.getElementById("playItem_" + this.thePlayletResId);
            if (el) {
              if (el.scrollIntoViewIfNeeded) {
                el.scrollIntoViewIfNeeded();
              } else {
                el.scrollIntoView();
              }
            }
          });
        } else {
          this.$message.error("Please contact the administrator!");
        }
      });
    },
    /**
     * 当前视频播放授权
     * @param {*} playletResId
     * @param isSeeCom
     */
    getVideoPlayAuth(playletResId, isSeeCom) {
      httpTools.getVideoPlayAuth(playletResId).then((res) => {
        if (res.status == "SUCCESS") {
          this.videoData = { ...this.videoData, ...res.content };
          if (isSeeCom) {
            this.videoData.playStatus = true;
          }
          this.initPlayer(this.videoData.playStatus);
        } else {
          this.$message.error("Please contact the administrator!");
        }
      });
    },
    /**
     * 阿里云播放器
     * @param {*} autoplay
     */
    initPlayer(autoplay = true) {
      // 删除贴片广告元素
      // document.getElementById("content")?.remove();
      // document.getElementById("mackDiv")?.remove();
      this.player = new Aliplayer(
        {
          id: "J_prismPlayer",
          width: "100%",
          height: "300px",
          autoplay: autoplay,
          isLive: false,
          encryptType: 1,
          cover: this.videoData.videoMeta.coverURL,
          vid: this.videoData.videoMeta.videoId, // 必选参数。音视频ID。示例：1e067a2831b641db90d570b6480f****。
          playauth: this.videoData.playAuth, // 必选参数。音视频播放凭证。
          skinLayoutIgnore: [
            // "bigPlayButton", // 隐藏大播放按钮
            "controlBar.subtitle",
            "controlBar.setting"
          ],
          clickPause: true,
          controlBarVisibility: "always",
          skinLayout: [
            { name: "bigPlayButton", align: "cc" },
            {
              name: "controlBar",
              align: "blabs",
              x: 0,
              y: 0,
              children: [
                { name: "progress", align: "blabs", x: 0, y: 44 },
                { name: "playButton", align: "tl", x: 15, y: 12 },
                { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                { name: "fullScreenButton", align: "tr", x: 10, y: 12 },
                { name: "subtitle", align: "tr", x: 15, y: 12 },
                { name: "setting", align: "tr", x: 15, y: 12 },
                { name: "volume", align: "tr", x: 5, y: 10 }
              ]
            }
          ]
        },
        (player) => {
          console.log("The player is created----getCurrentTime:", player.getCurrentTime);
        }
      );
      this.player.on("ended", () => {
        if (this.currentVideoIndex < this.videoDetailsData.playletResList.length - 1) {
          let item = this.videoDetailsData.playletResList[this.currentVideoIndex + 1];
          this.onClickPlay(item);
        }
      });
    },
    onClickPlayBtn() {
      if (this.player) {
        this.player.play();
      }
    },
    /**
     * 点击集数，播放
     * @param {} item
     */
    onClickPlay(item) {
      this.$router.push(`/videoDetails/${this.playletId}/${item.playletResId}`);
    },
    /**
     * 点击解锁
     */
    onClickPay() {
      let that = this;
      let isSeeCom = false;
      this.unblockDisabled = true;
      // this.showRewardAD(
      //   () => {
      //     // --广告已观看可以发放奖励--
      //     // that.videoData.isSeeCom = true;
      //     isSeeCom = true;
      //     if (!that.adRecordObj[that.playletId]) {
      //       that.adRecordObj[that.playletId] = [];
      //     }
      //     that.adRecordObj[that.playletId].push(that.videoData.playletResId);
      //     localStorage.setItem("kiwitok_seeAD_record", JSON.stringify(that.adRecordObj));
      //     that.$forceUpdate();
      //   },
      //   () => {
      //     // --广告未拉取到--
      //     that.unblockDisabled = false;
      //     that.$message.error("Advertisement not found, please try again!");
      //   },
      //   () => {
      //     // --激励广告位已关闭--
      //     that.unblockDisabled = false;
      //     // if (that.videoData.isSeeCom) {
      //     //   that.getVideoPlayAuth(that.thePlayletResId);
      //     // }
      //     that.getVideoPlayAuth(that.thePlayletResId, isSeeCom);
      //     // window.location.reload();
      //     // that.$router.replace({ path: `/videoDetails/${this.playletId}/${this.thePlayletResId}/false`, force: true });
      //   }
      // );

      this.unblockTimeOut = setTimeout(() => {
        this.$message.error("Unlocking failure!");

        // this.videoData.isSeeCom = true;
        // if (!this.adRecordObj[this.playletId]) {
        //   this.adRecordObj[this.playletId] = [];
        // }
        // this.adRecordObj[this.playletId].push(this.videoData.playletResId);
        // localStorage.setItem("kiwitok_seeAD_record", JSON.stringify(this.adRecordObj));
        this.unblockDisabled = false;
        // // 在此处执行您的逻辑
        // if (this.videoData.isSeeCom) {
        //   this.getVideoPlayAuth(this.thePlayletResId);
        // }
      }, 3000);
    },
    showRewardAD(successFun, failFun, closeFun) {
      googletag.cmd.push(() => {
        const rewardedSlot = googletag.defineOutOfPageSlot("/22872161438,23086401644/novatipsy.com_0806_1x1", googletag.enums.OutOfPageFormat.REWARDED).addService(googletag.pubads());
        if (rewardedSlot) {
          const slotRenderEnded = (evt) => {
            if (evt.isEmpty) {
              console.log("--广告未拉取到--");
              failFun();
              googletag.pubads().removeEventListener("slotRenderEnded", slotRenderEnded);
              return;
            }
          };
          googletag.pubads().addEventListener("slotRenderEnded", slotRenderEnded);
          const rewardedSlotReady = (evt) => {
            console.log("--广告展示--");
            evt.makeRewardedVisible();
            // 在此处执行您的逻辑
            googletag.pubads().removeEventListener("rewardedSlotReady", rewardedSlotReady);
          };
          googletag.pubads().addEventListener("rewardedSlotReady", rewardedSlotReady);

          const rewardedSlotGranted = (evt) => {
            console.log("--广告已观看可以发放奖励--");
            // 在此处执行您的逻辑
            successFun();

            googletag.pubads().removeEventListener("rewardedSlotGranted", rewardedSlotGranted);
          };
          googletag.pubads().addEventListener("rewardedSlotGranted", rewardedSlotGranted);

          const rewardedSlotClosed = (evt) => {
            console.log("--激励广告位已关闭--");
            closeFun();
            googletag.destroySlots([rewardedSlot]);
            googletag.pubads().removeEventListener("rewardedSlotClosed", rewardedSlotClosed);
          };
          googletag.pubads().addEventListener("rewardedSlotClosed", rewardedSlotClosed);

          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
          googletag.display(rewardedSlot);
        }
      });
    },
    /**
     * 关闭激励广告后，播放视频
     */
    onGoonPlayVideo() {
      this.videoData.isSeeCom = true;
      this.$forceUpdate();
      clearTimeout(this.unblockTimeOut);
      this.unblockDisabled = false;
      if (this.adRecordObj[this.playletId]) {
        this.adRecordObj[this.playletId] = [];
      }
      this.adRecordObj[this.playletId].push(this.videoData.playletResId);
      localStorage.setItem("kiwitok_seeAD_record", JSON.stringify(this.adRecordObj));
    },
    /**
     * 返回首页
     */
    onClickReturn() {
      this.$router.replace("/");
    }
  }
};
</script>
<style lang="scss" scoped>
.videoDetailsDiv {
  .topDiv {
    padding: 10px 14px;
    // text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .returnImg {
      width: 16px;
      height: 16px;
      display: block;
    }

    p {
      margin: 0px;
    }

    .title_p {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .videoDiv {
    position: relative;
    height: 300px;
    background: rgb(33, 34, 37);

    .videoPlayer {
      position: relative;
      overflow: hidden;

      #J_prismPlayer .aliplayer-control {
        display: block !important; /* 确保控制按钮显示 */
      }

      .playBtn {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        background: #fff;
        color: #2c3e50;
        z-index: 99;
        border-radius: 50%;
        font-size: 40px;
        line-height: 60px;
      }
    }

    .maskDiv {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 99999;
      width: 100%;
      height: 100%;
      background: rgba(33, 34, 37, 0.5);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .lockImg {
        width: 32.5px;
        height: 32.5px;
      }

      .lockDsc {
        font-size: 16px;
        font-weight: 600;
      }

      .btn {
        width: 173px;
        height: 45px;
        background: #5f74ff;
        border-radius: 5px;
        // line-height: 45px;
        font-weight: bold;
        margin: auto;
        font-size: 18px;
        border: none;
        color: #fff;
      }

      .disabledBtn {
        pointer-events: none;
        cursor: default; /* 这将改变鼠标光标为默认样式，表明该元素不可点击 */
        filter: grayscale(100%);
      }
    }

    #ad-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  #mainContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    #content,
    #adContainer,
    #coverImgDiv {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    #coverImgDiv {
      background: #000;

      img {
        // width: 100%;
        height: 100%;
      }
    }

    video {
      width: 100%;
      height: 100%;
    }
  }

  .contentDiv {
    padding: 16px;
    text-align: left;

    p {
      margin: 0px;
    }

    .title_p {
      font-size: 18px;
      font-weight: bold;
    }

    .mg_top_24 {
    }

    .desc_p {
      font-size: 14px;
      color: rgba(33, 34, 37, 0.65);
      font-weight: normal;
      margin-top: 8px;
      margin-bottom: 24px;
    }

    .desc_p2 {
      margin-left: 5px;
      font-size: 14px;
      color: rgba(33, 34, 37, 0.65);
      font-weight: normal;
    }

    .desc_p3 {
      margin-top: 8px;
      margin-bottom: 10px;
      font-size: 14px;
      color: rgba(33, 34, 37, 0.65);
      font-weight: normal;
    }

    .playList {
      display: flex;
      overflow-x: auto;
      // flex-wrap: wrap;
      padding-bottom: 10px;

      .playItem {
        margin-right: calc((100% - 288px) / 5);
        // margin-top: 10px;
        position: relative;
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        line-height: 48px;
        background-color: #f2f4f7;
        color: #212225;
        font-weight: bold;
        border-radius: 3px;
        text-align: center;

        .payImg {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 22.5px;
        }

        // .lockDiv {
        //   position: absolute;
        //   left: 0px;
        //   top: 0px;
        //   width: 100%;
        //   height: 100%;
        //   background: rgba(33, 34, 37, 0.5);

        //   .lockImg {
        //     width: 18px;
        //   }
        // }
      }

      .playItem_active {
        color: #5f74ff;
        background-color: #eff1ff;
      }

      // .playItem:nth-child(6n) {
      //   margin-right: 0px;
      // }
      .playItem:last-child {
        margin-right: 0px;
      }
    }
  }
}
</style>
<style>
.el-message {
  z-index: 99999 !important;
}
</style>
