<template>
  <div>
    <div class="company">
      <h2 class="content_title">Privacy Policy</h2>
      <div class="mainBox" id="mainBox">
        <p>We do not collect personally identifiable information about you, unless you choose to fill your personal</p>
        <p>information on our website. Predominantly, you may browse our website without providing</p>
        <p>any personally identifiable information about yourself. However, we collect information that you supply to</p>
        <p>us when you personally choose to contact us for more information. You supply the</p>
        <p>information directly to us or indirectly by using credentials from third parties such as Facebook, Twitter,</p>
        <p>Google+ or LinkedIn. This data includes your name, date of birth, email address,</p>
        <p>contact number, contact address or other similar information. This information collected by us is used to</p>
        <p>respond to your inquiry to develop products, services and programs or process an order.</p>
        <p><br /></p>
        <p>Our web-site uses cookies. Cookies are small text files of information which save and retrieve information</p>
        <p>about your visit to our website. Cookies, by themselves, do not give us any personally</p>
        <p>identifiable information about you unless you choose to provide this information to us. A cookie is like an</p>
        <p>identification card. It is unique to your computer and can only be read by the server</p>
        <p>that gave it to you.</p>
        <p>We use cookies to understand site usage and to improve the content and offerings on our web-site. We use</p>
        <p>cookies to personalize your experience on our web-page.</p>
        <p>If you do not want information collected through the use of cookies, there is a simple procedure in most</p>
        <p>browsers that allows you to deny or accept the cookie feature. However, if you do not</p>
        <p>configure your browser you will accept cookies provided by this web-site. Note that certain features of our</p>
        <p>products and services may not function properly without the aid of cookies.</p>
      </div>
      <!-- <el-backtop :right="20" :bottom="20"></el-backtop> -->
      <el-backtop :bottom="160" :right="15">
        <div class="upBtn"><img class="icon" src="@/assets/toTop.png" alt="" /></div>
      </el-backtop>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacyPolicy",
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.company {
  padding: 20px 10px;
  background: #eff1f4;
  text-align: left;
  line-height: 1.6;
  a,
  ul li {
    list-style: none;
  }
  article,
  aside,
  body,
  dd,
  div,
  dl,
  dt,
  footer,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  img,
  li,
  main,
  nav,
  p,
  section,
  span,
  table,
  td,
  tr,
  ul {
    margin: 0;
    padding: 0;
  }
  h2 {
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin-bottom: 15px;
    color: #333;
    font-weight: 700;
  }
  .mainBox {
    background-color: #fff;
    padding: 16px;
    border-radius: 4px;
    p {
      font-size: 0.9em;
      margin-bottom: 15px;
      line-height: 1.6em;
      color: #516375;
    }
  }
}
.upBtn {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #007eff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    width: 50%;
  }
}
</style>
