import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

let store = new Vuex.Store({
  state: {
    videoDetails: "",
    showADIdList: {}
  },
  getters: {},
  mutations: {
    update_videoDetails(state, data) {
      state.videoDetails = data;
    },
    push_showADIdList(state, data) {
      for (let key in data) {
        state.showADIdList[key] = data[key];
      }
    },
    delete_showADIdList(state, data) {
      for (let key in data) {
        delete state.showADIdList[key];
      }
    }
  },
  actions: {}
});
export default store;
