import axios from "axios";
let _timeout = 60000;
// let BASEURL = "https://api.easyhope.com";//测试
let BASEURL = "https://api.kiwitok.com"; //正式
// let BASEURL = process.env.NODE_ENV !== "production" ? "https://api.easyhope.com" : "https://api.kiwitok.com";
let tag = "[sdk/api/request] - ";
// 需要忽略的提示。忽略后，自动 Promise.reject('error')
const ignoreMsgs = [
  "无效的刷新令牌", // 刷新令牌被删除时，不用提示
  "刷新令牌已过期" // 使用刷新令牌，刷新获取新的访问令牌时，结果因为过期失败，此时需要忽略。否则，会导致继续 401，无法跳转到登出界面
];
const errorCode = {
  401: "认证失败，无法访问系统资源",
  403: "当前操作没有权限",
  404: "访问资源不存在",
  default: "系统未知错误，请反馈给管理员"
};

// Axios 无感知刷新令牌，参考 https://www.dashingdog.cn/article/11 与 https://segmentfault.com/a/1190000020210980 实现
// 请求队列
let requestList = [];
// 是否正在刷新中
let isRefreshToken = false;

// axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: BASEURL, // 此处的 /admin-api/ 地址，原因是后端的基础路径为 /admin-api/
  // 超时
  timeout: _timeout,
  // 禁用 Cookie 等信息
  withCredentials: false
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["appKey"] = "81001112";

    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?";
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        const part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof value !== "undefined") {
          if (typeof value === "object") {
            for (const key of Object.keys(value)) {
              let params = propName + "[" + key + "]";
              const subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  async (res) => {
    // 未设置状态码则默认成功状态
    let code = res.data.code || 200;
    // 获取错误信息
    const msg = res.data.msg || errorCode[code] || errorCode["default"];
    if (res.config.responseType == "arraybuffer") {
      try {
        const str = JSON.parse(new TextDecoder("utf-8").decode(new Uint8Array(res.data)));
        console.log(["str", str]);
        if (str.code === 401) {
          code = 401;
        }
      } catch (error) {
        return res.data;
      }
    }
    if (ignoreMsgs.indexOf(msg) !== -1) {
      // 如果是忽略的错误码，直接返回 msg 异常
      return Promise.reject(msg);
    } else if (code === 401) {
      // 如果未认证，并且未进行刷新令牌，说明可能是访问令牌过期了
      if (!isRefreshToken) {
        isRefreshToken = true;
        // 2. 进行刷新访问令牌
        try {
          console.log(["进行刷新访问令牌-----接口"]);
          // res.config.headers.Authorization = refreshTokenRes.data.accessToken;
          // ipcRenderer.send("mc-update-token", refreshTokenRes.data);
          requestList.forEach((cb) => cb());
          return service(res.config);
        } catch (e) {
          console.log(["进行刷新访问令牌-----失败", e]);
          // 为什么需要 catch 异常呢？刷新失败时，请求因为 Promise.reject 触发异常。
          // 2.2 刷新失败，只回放队列的请求
          requestList.forEach((cb) => cb());
          // 提示是否要登出。即不回放当前请求！不然会形成递归
          return handleAuthorized();
        } finally {
          requestList = [];
          isRefreshToken = false;
        }
      } else {
        // 添加到队列，等待刷新获取到新的令牌
        return new Promise((resolve) => {
          requestList.push(() => {
            // res.config.headers["Authorization"] = "Bearer " + getAccessToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
            resolve(service(res.config));
          });
        });
      }
    } else if (code === 500) {
      // Message({
      //     message: msg,
      //     type: 'error'
      // })
      return Promise.reject(msg);
    } else if (code === 501) {
      // Message({
      //     type: 'error',
      //     duration: 0,
      //     message: msg
      // })
      return Promise.reject(new Error(msg));
    } else if (code === 901) {
      // Message({
      //     type: 'error',
      //     duration: 0,
      //     dangerouslyUseHTMLString: true,
      //     message: '<div>演示模式，无法进行写操作</div>'
      //         + '<div> &nbsp; </div>'
      //         + '<div>参考 https://doc.iocoder.cn/ 教程</div>'
      //         + '<div> &nbsp; </div>'
      //         + '<div>5 分钟搭建本地环境</div>',
      // })
      return Promise.reject(new Error(msg));
    } else if (code !== 200 && code !== 10000025) {
      // 10000025是不可重复参会
      if (msg === "无效的刷新令牌") {
        // hard coding：忽略这个提示，直接登出
        console.log(msg);
      } else {
        // Notification.error({
        //   title: msg
        // });
      }
      return Promise.reject(msg);
    } else {
      return res.data;
    }
  },
  (error) => {
    console.log(`接口异常：${error.config.url}  ${JSON.stringify(error)}`);
    if (axios.isCancel(error)) {
      return Promise.reject("请求被取消了！");
    }
    let message = JSON.stringify(error);
    console.log("err234567890~~~~~~~~~~~~" + message);
    if (message.includes("Network Error")) {
      message = "后端接口连接异常";
      console.log(["Network Error", message]);
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
      console.log(["timeout", message]);
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
      console.log(["Request failed with status code", message]);
    }
    // ipcRenderer.send("openErrorAlert", { type: "networkError", content: message });
    console.log("err1234567890~~~~~~~~~~~~");
    return Promise.reject(message);
  }
);

function handleAuthorized() {
  return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
}

export default service;
