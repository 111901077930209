<template>
  <div class="company">
    <h2 class="content_title">User Agreement</h2>
    <div class="mainBox" id="mainBox">
      <h1 class="ql-align-center">Chapter 1: General Provision</h1>
      <h2>Article 1 (Purpose)</h2>
      <p>These Terms and Conditions have an intention that regulate rights, obligations and responsibilities of company and users when the user uses internet service Company provides.</p>
      <h2>Article 2 (Effect or Change of Terms and Conditions)</h2>
      <ul>
        <li>1. The Terms and Condition have an effect when the Company announce it on the website or by email.</li>
        <li>2. The Company could change the Terms and Conditions without pre-notice when they have an inevitable reason. In this case the company will announce the change though service.</li>
        <li>
          3. Users can request withdrawal of membership when they does not want to agree with the changed Terms and Condition. In the case that an user didn’t request withdrawal of membership within 10 days from the day when the changed terms and
          condition became effective, the user would be considered that he or she agrees with the changes.
        </li>
      </ul>
      <h2>Article 3 ( Rules besides the Terms and Condition)</h2>
      <p>Counts which are not specified in this Terms and Condition will follow the Telecommunications Act, Promotion of Information and Communications Network Utilization and Information Protection Act and other related laws and regulations.</p>
      <h2>Article 4 (Definition of Terminology)</h2>
      <p>Major Terminologies which are used in this Terms and Conditions.</p>
      <ul>
        <li>1. A Member : A Person who made a utilization contract with the company and is given approved a series of Characters, numbers or combination of them (From now on, will be named as ID) by the Company.</li>
        <li>2. An ID : A series of characters, numbers or combination of them - which are made by an member and approved by the company- are used to identify users and for the utilization of service.</li>
        <li>3. A Password : A series of characters, numbers or combination of them which confirms if a member is matched with ones own ID. Thanks to Password the members can protect their personal information.</li>
        <li>4. A Nickname: A series of characters, numbers or combination of them - which are made by each member and approved by the company - are used to identify, name, represent themselves.</li>
        <li>5. Suspensions : Under the Terms and conditions , the company restrict members utilization of service.</li>
        <li>6. Cancellation : The Company or a member cancel utilization contract after using the service.</li>
        <li>7. Point: Score which is given to each member and could increase or decrease as per degree of participation and utilization in the service.</li>
      </ul>
      <h1 class="ql-align-center">Chapter 2 Contract of Service Utilization</h1>
      <h2>Article 5 (Establishment of Utilization Contract)</h2>
      <ul>
        <li>1. When applying for a service contract, If a service applicant presses Accept button after reading the Terms and condition, he/she would be considered to have agreed with the agreement(Terms and Condition).</li>
        <li>2. Utilization Contract would be established after agreement of service applicant and approval of application by the company.</li>
        <li>3. If a child under 14 years old wants to utilize the service, he/she needs to go through parental consent procedure which company insists, and then utilization contract will be finally established.</li>
      </ul>
      <h2>Article 6 (Application For Utilization)</h2>
      <p>If Someone wants to use the service as a member, he/she needs to provide ones personal information depending on the prescribed form the company designated.</p>
      <h2>Article 7 ( Approval of Application For Utilization)</h2>
      <ul>
        <li>1. The company approves the application following Article 6 in order if there isn’t any special occasion.</li>
        <li>2. The company could reserve it’s judgement regarding approval of application by the reason is settled. And the reasons are as follows.</li>
        <li class="ql-indent-1">1) When the company lacks service facilities.</li>
        <li class="ql-indent-1">2) When they have technological inconvenience.</li>
        <li class="ql-indent-1">3) Besides, it is recognized that the company has unavoidable reasons..</li>
        <li>3. The Company could not approve the application when the application case corresponds with the following situations.</li>
        <li class="ql-indent-1">1) The service applicant didn’t use his/her own name.</li>
        <li class="ql-indent-1">2) The service applicant under 14 years old didn’t get the parental consent from his/her legal representative(parents).</li>
        <li class="ql-indent-1">3) The service applicant uses other individual’s name.</li>
        <li class="ql-indent-1">4) The service applicant puts false information on application blank.</li>
        <li class="ql-indent-1">5) The service applicant applies for the service with an object of disturbing peace and order or public morals of society.</li>
        <li class="ql-indent-1">6) When the contact information (telephone number, cellular phone number) is uncertain</li>
        <li class="ql-indent-1">7) Besides, prerequisite which company settled are not fully satisfied.</li>
        <li>
          4. Under the Article 2 or Article 3, if the company reserve or disqualify an application, he should notice intention of refusal to the service applicant. But it is exception that the company couldn’t notify applicant without service
          provider’s legal reasons.
        </li>
        <li>5. Within 90 days after having terminated membership, if ex-member tries to apply for the service with the same personal information, the company would not accept the application.</li>
      </ul>
      <h2>Article 8 (Protection of Personal Information)</h2>
      <ul>
        <li>1. The company expects and respects the privacy of its members.</li>
        <li>
          2. The company The company collects information provided by the member through using application, community activities, the other services utilization and for event participation. In addition, personal information of members will be used
          for the purpose for the services provided on this subscription and for the performance of this contract.
        </li>
        <li>
          3. company cannot leak or distribute to a third party the personal information of members acquired in connection with the provision of services without the consent of the individual. Also, the personal information cannot be used for
          commercial purposes. However, if a case is applied to the following items, and it will not be applied.
        </li>
        <li class="ql-indent-1">1) If it is necessary communication service fee settlement for telecommunications services.</li>
        <li class="ql-indent-1">2) If it is necessary for statistics creation, scientific research or market research, the information is provided by processing into a form that it is not possible to recognize a specific individual</li>
        <li class="ql-indent-1">3) By relevant laws and regulations, and if there is a request of the relevant agencies by procedures and methods defined in the investigation purpose.</li>
        <li class="ql-indent-1">4) If there are special provisions in other laws</li>
        <li class="ql-indent-1">5) If it has been requested by the relevant laws and regulations from the GDPR.</li>
        <li>
          4. Within the scope of the third term, the company creates a collective statistics on all or part of the personal information of the members in connection with the company operations and can use it. In addition, the company can send a
          cookie to a member through the service. In this case, members can refuse or receive cookies and change the setting of a computer browser that is used to warn against reception of cookies.
        </li>
        <li>5. If the user is under the following cases, the company will be able to arbitrarily change the personal information of members.</li>
        <li class="ql-indent-1">1) In the case a member requests change of the personal information directly to the company.</li>
        <li class="ql-indent-1">2) In the case that false information is detected, or an error in the input information is detected, the company could correct this.</li>
        <li class="ql-indent-1">
          3) In the case that ID or nickname of the member contains the contents of the personal information such as phone number or social security number, therefore there is a risk that invades private life of members from the others.
        </li>
        <li class="ql-indent-1">4) In the case of the ID or nickname which is contrary to public policy or disgusts the others.</li>
        <li class="ql-indent-1">5) If there are other given reasonable grounds</li>
        <li>
          6. On the basis of Section 5 and 22, paragraph 3 of Article 7, personal information of members who apply for termination of the service, the company will retain his/her personal information for 90 days. However, on the basis of paragraph 2
          Article 15, the personal information of members who use the paid services, for the protection of the settlement and transaction information on the basis of the "Law on the Promotion of Information Communications Network and Information
          Protection" the company could retain members information for 120days after termination completion. In this case also, the company could use the information for the original purpose personal information which is to protect settlement and
          transaction information and cannot use it for other commercial purposes.
        </li>
        <li>7. For more information related to the protection of personal information, please refer to the Privacy Policy.</li>
      </ul>
      <h2>Article 9 (Use of Personal Information)</h2>
      <ul>
        <li>1. Personal information that company collects is to minimize necessity of requested information for the service. However the company can request more detailed information if needed.</li>
        <li>
          2. The company could provide the personal information of members who participated in the various events that takes place in the company of the site with the consent of the member and the information can be provided to the organizers and the
          third person of the event. Even in such a case, the provision of the personal data to third parties is performed under the consent of the user only, if members does not want their information to be provided, do not participate in a
          particular type of promotional and events.
        </li>
        <li>
          3. If your company consigns specific services to outside vendors (Below consigned company), it is possible to provide necessary personal information of members that is entrusted with the consent of the members to the consigned company. In
          this case the company should express the service commissioned fact. Consigned company which is entrusted to the collection of personal information of should only use it for the purposes that were commissioned and does not provide it to any
          third party.
        </li>
        <li>
          4. The company receives an consent from user who uses the service the company provides of the user's personal information that can personally identify users for qualitative and quantitative improvement, and collects the information and can
          use it for custom services, online advertising service, community service, paid content service such as in mobile services.
        </li>
        <li>
          5. In order to improve the quality of service provided to the member, the online advertising services, shopping mall service, community services, pay content services, mobile services, insurance, telemarketing services such as credit cards,
          creates statistics or research market, the company can tie a partnership with professional content operators and business operators in various fields for the purpose of providing a variety of services.
        </li>
        <li>
          6. The company provides personal information of members to a partnership company who signed a partnership of Section 5, if the company is willing to share the information, they always take consent of the user, and provide a minimum of
          information necessary to partners. Moreover the company indicates when, for whom, what information and what purposes (affiliate name, the purpose of cooperation, personal information sharing)
        </li>
        <li>7. During the use of personal information of the same Section 3 to 6 Section, the consent of the user, can be replaced by that agreement to this Terms and Conditions.</li>
        <li>8. For Members, it is possible to withdraw consent to the collection and utilization of personal information that provided to the company at any time, if necessary, withdrawal of consent is done by making the revocation request.</li>
      </ul>
      <h2>Article 10 (Change of the Contract)</h2>
      <ul>
        <li>1. Members can use the management of personal information at any time and view and change the personal information of own.</li>
        <li>2. Members, if the items described when using application has been changed, the responsibility of the problems that occur without changing the membership information which should be changed on-line is on the member.</li>
        <li>
          3. If a member desired, it is possible to withdraw the use agreement. If you withdraw the use consent, you can follow the constraints on service utilization of the company. Withdrawal of utilization agreement is done by making the
          revocation request.
        </li>
      </ul>
      <h1 class="ql-align-center">Chapter 3 Obligations of The Contracting Parties</h1>
      <h2>Article 11 (Company’s Obligation)</h2>
      <ul>
        <li>1. The company, unless special circumstances, let the members use the service on the day that he/she sign for the use of the service.</li>
        <li>
          2. Company should continuously strive for the provision of high continuous reliable service pursuant to the provisions of this Agreement. When a failure or disorder caused to equipment, the company must repair this without delay. However,
          in case of natural disaster, an emergency or other is unavoidable, they can pause or stop the service temporarily.
        </li>
        <li>
          3. The Company, if the opinions and complaints that are raised by a predetermined procedure from members deemed a legitimate, need to process it through the appropriate steps. If it takes a period of time during the treatment, the company
          will notify the reasons and processing schedule to its members.
        </li>
        <li>4. In conjunction with the privacy protection of members, the company protects the contents that are presented in Article 8.</li>
        <li>5. In the conclusion of the agreement, procedure, contents and etc. which are related to the contract and its change of contract matters and cancellation, the company will strive to provide a convenience to the members.</li>
      </ul>
      <h2>Article 12 (Obligation of the member)</h2>
      <ul>
        <li>1. In this terms the members must comply with the matters through such matters with this article or notice from the company and shall not interfere with the company businesses.</li>
        <li>
          2. All of the management responsibility of ID and password are on the member. Results due to the lack of management of ID and password that have been granted to the member, responsibility for all of the results generated by the unauthorized
          use is located in the member.
        </li>
        <li>
          3. If members have found the fact that their ID and password is used illegally, it is necessary to notify it immediately to the company. If not, all of the results that occur when you do not have the declaration are responsible for the
          member.
        </li>
        <li>
          4. Members cannot carried out sales activities by using the service without prior consent of the company. And for the results that occurred operating activities that member has violated the terms, the company does not take responsibility.
          If the company has suffered damages in such operating activities, members will accept obligation from the damages to the company.
        </li>
        <li>5. As long as there is no explicit consent of the company, utilization authority of the service and the other utilization contractual status cannot be gifted or provided as collateral.</li>
        <li>6. In conjunction with the services utilization, members must not do such activities corresponding to each of the following items.</li>
        <li class="ql-indent-1">1) Act of stealing other member of the ID and password, nickname, mobile phone, etc.</li>
        <li class="ql-indent-1">
          2) Members cannot duplicate or publish the information obtained through this service for other purpose without prior consent of the company, you can use in such as publishing and broadcasting this, act to provide to a third party.
        </li>
        <li class="ql-indent-1">3) Circulation of patent, trademark, trade secret or post content that infringes copyright and other intellectual property by way of e-mail or other activities to others</li>
        <li class="ql-indent-1">4) Circulation of contents that violate the order or policy of the public and information of obscene content, text, graphics, etc. by e-mail or other methods.</li>
        <li class="ql-indent-1">
          5) The dissemination of offensive or threatening contents that can violate the privacy of others, or blindness, place of residence, and sending the information related to the personal information of others, such as contacts, posts by e-mail
          or other methods .
        </li>
        <li class="ql-indent-1">6) Inducement a dispute between members in the community or fan site which companies operate and habitual performance contrary to Community</li>
        <li class="ql-indent-1">7) Acts objectively judged that associated with crime</li>
        <li class="ql-indent-1">8) Act of saving or collecting personal information without approval of the other members and the company.</li>
        <li class="ql-indent-1">9) Any violation of other laws</li>
      </ul>
      <h1 class="ql-align-center">Chapter 4 Utilization of the service</h1>
      <h2>Article 13 (Range of the Service)</h2>
      <p>Members can utilize company’s service by using ID and nickname which are issued when the member is registered. However, by the authentication and membership level, utilization of some service could be limited.</p>
      <h2>Article 14 (Provision of Information)</h2>
      <p>
        The company will be able to provide various information which is deemed to be needed in service utilization to the member by a method such as announcements and e-mail. In order to provide the benefits of better service, the company will be
        able to provide service-related information via a variety of delivery methods (phone, guidance statement, such as e-mail) to its members. However, in the case where the information provided that members clarify an intention that do not wish
        to provide service benefits information, it is necessary to except the members. And the company is not responsible of the disadvantage from those cases.
      </p>
      <h2>Article 15 (Point system)</h2>
      <p>Company can provide points earning opportunity or grant points in order to provide smooth service. Acquisition and reduction of points, with respect to the use, is set individually through the operational policy of point system.</p>
      <h2>Article 16 (Fare, Payment information or settlement)</h2>
      <ul>
        <li>1. Basically is the service that the company provides free.</li>
        <li>
          2. If your company provides another paid service and paid information, the company must guide about the establishment and use of the service, for a member which takes advantage of this. And fee which is described in the information must be
          paid.
        </li>
      </ul>
      <h2>Article 17 (Members’ Posts)</h2>
      <p>If the contents which are registered or posted by members on the service are determined to correspond to each of the following items, the company can delete it without prior notice.</p>
      <ul>
        <li>1. In case of contents which slander other members or third parties or damage other people’s honor</li>
        <li>2. In case of contents that violate public order or public policy.</li>
        <li>3. In case of contents which is deemed to lead to criminal behavior</li>
        <li>4. In case of contents which infringe company’s copyright or other rights such as copyright of a third party</li>
        <li>5. In case of contents which exceed the posted time and space that is defined in the company</li>
        <li>6. If a member posts link or contents of a porn on his own homepage or bulletin board.</li>
        <li>7. In the case of post which does not match to the personality of bulletin board.</li>
        <li>8. In the case of a post corresponding to Article 12 Section 6</li>
        <li>9. If it is determined to violate other laws</li>
      </ul>
      <h2>Article 18 (Copyright of Post)</h2>
      <p>Rights of materials which are posted in the service are the following items.</p>
      <ul>
        <li>
          1. The rights and responsibilities of the post belong to posting person, and the company, without the issuer's consent, cannot use it for commercial purposes. However, on non-commercial purpose it is also not as long and the company has
          rights to pose it in the service.
        </li>
        <li>2. Members, processing the information obtained by use of the service, will not be able to commercially use the listed materials in services such as act of sale.</li>
      </ul>
      <h2>Article 19 (Advertising and Transactions with advertisers)</h2>
      <ul>
        <li>
          1. Some services investment infrastructure from which the company can provide a service to the member comes out of the profit through advertising. A person who intends to use the service, it is deemed to have consented to the advertising
          that is exposed at the time of service use.
        </li>
        <li>2. Company does not be responsible for loss or damage of the member which occur as a result of promotional activities of advertisers though service or posted on the service</li>
      </ul>
      <h2>Article 20 (Service Utilization Time)</h2>
      <ul>
        <li>1. Use of service could be done 24hours per day, 365 days per year as long as there is no business or technical on special trouble of company. However, it is not applied to the case in which it needs to have periodic inspection.</li>
        <li>2. The company can divide service hour within a certain range, which is separately determined by the available time for each range. In this case, the company notifies it to the member.</li>
      </ul>
      <h2>Article 21 (Responsibility of Service Utilization)</h2>
      <p>
        Members, with the exception of when the authorized employees of the company has specifically allowed it by writing signed, are unable to perform the sales activities of selling goods using the service. In particular, hacking, lucrative
        advertising and commercial act through such adult site or S / W illegal distribution, should not be done commercially. The company does not take responsibility of results, loss, or legal action from those business activities that have
        occurred breaking the law.
      </p>
      <h2>Article 22 (Stopping the Service Provision and etc.)</h2>
      <ul>
        <li>1. Company, if it is one of the following items, will be able to stop the provision of services.</li>
        <li class="ql-indent-1">1) If it is unavoidable due to construction work such as repair services for equipment</li>
        <li class="ql-indent-1">2) If the core telecom operators as defined in the Telecommunications Business Law has ceased telecommunications services</li>
        <li class="ql-indent-1">3) If there are other majeure specific reason</li>
        <li>When there is national emergency, power failure, troubles in normal service or failure due to concentration of users, the company will be able to stop or limit the whole or part of the service.</li>
        <li>Company, when the service needs to be limited or stopped in accordance with the provisions of the first and second terms, will notify reasons and limitations period without delay to members .</li>
      </ul>
      <h1 class="ql-align-center">Chapter 5 Contract cancellation and Utilization restrictions</h1>
      <h2>Article 23 (Termination of the and limits of agreement)</h2>
      <ul>
        <li>1. When a Member is trying to cancel the subscription, the member himself will have to apply for the cancelation form through online.</li>
        <li>2. In the case of a member has committed an act corresponding to each of the following items, the company can terminate or limit for certain period of time the service contract without prior notice.</li>
        <li class="ql-indent-1">1. In the case of a member steals personal information, ID and password of the others.</li>
        <li class="ql-indent-1">2. In the case that the registered name entered during registration is false is not a real name.</li>
        <li class="ql-indent-1">3. In the case of a member damages the honor of others or gives disadvantage to another members.</li>
        <li class="ql-indent-1">
          4. Aggressive or so threatening action that can violate the privacy of others, blindness or place of residence, and send information related to the personal information of others, such as contacts, posts by e-mail or other methods.
        </li>
        <li class="ql-indent-1">5. In the case of a member violates other individuals’ or any third party’s rights of intellectual property.</li>
        <li class="ql-indent-1">6. In the case of a member spreads contents which are inhibited in order and public policy.</li>
        <li class="ql-indent-1">7. In the case of a member plans or execute a service in an attempt to inhibit national interest or social public interest.</li>
        <li class="ql-indent-1">8. If a member interferes with the service management deliberately, for example, inducing a habitual dispute or acting contrary to the community</li>
        <li class="ql-indent-1">9. If a member sends a lot of information for the purpose of interfering with the service stable operation or posting free ads of information</li>
        <li class="ql-indent-1">10. In the case of inducing breakdown of malfunction or information of the communication equipment disseminating such as computer virus programs</li>
        <li class="ql-indent-1">
          11. Whether there is a correction request of external organizations such as the Information and Communication Ethics Committee, in connection with illegal campaigning, therefore the company has received the ownership interpretation of
          Election Commission
        </li>
        <li class="ql-indent-1">12. If a member posts materials, for example a link the obscene site or spreads obscene information.</li>
        <li class="ql-indent-1">13. If a member doesn’t have recent service usage records(login record) within six months.</li>
        <li class="ql-indent-1">14. In the case a member violates an agreement of other companies, including this agreement.</li>
        <li class="ql-indent-1">15. If a member attempts to use the company's services by bugs and irregular way</li>
        <li>
          3. The company should process request of cancelation immediately within 90 days from when a member apply for re-using request of ID. If there is no request, the company considers that the member would not have intention of further
          utilization of the service.
        </li>
      </ul>
      <h1 class="ql-align-center">Chapter 6 Damages and Other Matters</h1>
      <h2>Article 24 (Compensation)</h2>
      <p>Company shall not be responsible for any damage caused to the member in connection with the free service utilization.</p>
      <h2>Article 25 (Escape Clause)</h2>
      <ul>
        <li>1. If the company could not service due to the majeure natural equivalent or disasters, the responsibility of the company will be waived.</li>
        <li>2. The Company will not hold responsibility for the failure of the service from the imputation reasons.</li>
        <li>3. Although the company has lost revenue from the members using the service, the company will not be responsible for the damage caused by the obtained documents through the service.</li>
        <li>4. The company will not be responsible for the content and the confidence of Information that member has been posted on the service, materials.</li>
        <li>5. In connection with the service utilization, the company will not be responsible for the damage that occurred due to the members negligence or failure.</li>
      </ul>
      <h2>Article 26 (The Competent Court)</h2>
      <p>If a lawsuit against conflict during service utilization is occurred, the company will design the competent court in the area which company’s head office is located.</p>
    </div>
    <!-- <el-backtop :right="20" :bottom="20"></el-backtop> -->
    <el-backtop :bottom="160" :right="15">
      <div class="upBtn"><img class="icon" src="@/assets/toTop.png" alt="" /></div>
    </el-backtop>
  </div>
</template>

<script>
export default {
  name: "userAgreement",
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.company {
  padding: 20px 10px;
  background: #eff1f4;
  font-family: roboto, sans-serif;
  text-align: left;
  line-height: 1.6;
  a,
  ul li {
    list-style: none;
  }
  article,
  aside,
  body,
  dd,
  div,
  dl,
  dt,
  footer,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  img,
  li,
  main,
  nav,
  p,
  section,
  span,
  table,
  td,
  tr,
  ul {
    margin: 0;
    padding: 0;
  }
  h2 {
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin-bottom: 15px;
    color: #333;
    font-weight: 700;
  }
  .mainBox {
    background-color: #fff;
    padding: 16px;
    border-radius: 4px;
    p {
      font-size: 0.9em;
      margin-bottom: 15px;
      line-height: 1.6em;
      color: #516375;
    }
  }
}
.upBtn {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #007eff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    width: 50%;
  }
}
</style>
